import { useEffect, useState } from "react";

import type ComboboxOption from "../components/Combobox/models/ComboboxOption.js";

export type LoadOptionMapFn = (
  values: string[]
) => Promise<Map<string, ComboboxOption<string>>> | Map<string, ComboboxOption<string>>;

export type OptionMap = Map<string, ComboboxOption<string>>;

export interface OptionMapLoaderProps {
  values: string[];
  initialOptionMap?: OptionMap;
  initialOptions?: Array<ComboboxOption<string>>;
  loadOptionMap?: LoadOptionMapFn;
}

/**
 * Custom hook for transforming string form values into ComboboxOption<string>s, utilized
 * by SelectInput and MultiSelectInput components. This hook handles both static
 * and asynchronous select inputs:
 *
 * - For static selects, it directly uses the provided options list.
 * - For asynchronous selects, it supports two methods:
 *   1. `initialOptionMap`: Uses a pre-defined map of select values that is expected to match the values list.
 *   2. `loadOptionMap`: Uses a function to dynamically load options upon component mount.
 *
 * If a value is not found in the options map, the hook defaults to using the value itself as the label.
 * Post-initial load, the options map can be updated based on user selections from the combobox.
 *
 * @author @mxs2019
 */
export function useOptionMapLoader({
  values,
  initialOptionMap,
  initialOptions,
  loadOptionMap,
}: OptionMapLoaderProps): {
  selectedOptions: Array<ComboboxOption<string>>;
  addOptions: (newOptions: Array<ComboboxOption<string>>) => void;
} {
  const [isLoadingOptionMap, setIsLoadingOptionMap] = useState<boolean>(!!loadOptionMap);
  const [optionsMap, setOptionsMap] = useState<Map<string, ComboboxOption<string>>>(
    initialOptionMap ?? new Map(initialOptions?.map((o) => [o.value, o]) ?? [])
  );

  useEffect(() => {
    async function loadOptions() {
      if (loadOptionMap) {
        try {
          const newOptionsMap = await loadOptionMap!(values);
          setOptionsMap(new Map([...optionsMap, ...newOptionsMap]));
        } finally {
          setIsLoadingOptionMap(false);
        }
      }
    }

    void loadOptions();
  }, [loadOptionMap]);

  const selectedOptions = values.map(
    (v) =>
      optionsMap.get(v) ?? {
        value: v,
        isLoading: isLoadingOptionMap,
        label: isLoadingOptionMap ? undefined : v,
      }
  );

  const addOptions = (newOptions: Array<ComboboxOption<string>>) => {
    const newEntries = newOptions.map((o) => [o.value, o] as [string, ComboboxOption<string>]);
    setOptionsMap(new Map([...optionsMap, ...newEntries]));
  };

  return { selectedOptions, addOptions };
}
